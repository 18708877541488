import "./scrollup.css"
import React, { useEffect, useState } from 'react'

const ScrollUp = () => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 560) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <a href="#home" className={showScroll ? "scrollup show-scroll":"scrollup"}>
            <i className="ui uil-arrow-up scrollup_icon"></i>
        </a>
    );
}

export default ScrollUp
