import React from 'react'

const Software = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">Software Development</h3>
            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">React & Node</h4>
                            <span className="skills_level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Python & Java</h4>
                            <span className="skills_level">Advanced</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">MySql & MongoDB</h4>
                            <span className="skills_level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Tailwind</h4>
                            <span className="skills_level">Intermediate</span>
                        </div>
                    </div>

                   

                </div>


                <div className="skills_group">

                    
                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">HTML & CSS</h4>
                            <span className="skills_level">Advanced</span>
                        </div>
                    </div>


                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Github & Gitlab</h4>
                            <span className="skills_level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">PHP & Javascript</h4>
                            <span className="skills_level">Advanced</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Kotlin</h4>
                            <span className="skills_level">Intermediate</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Software