import React from 'react'
import Scroll from "../../assets/scroll.gif"

const ScrollDown = () => {
  return (
    <div className="home_scroll">
        <a href="#about" className="home_scroll-button">
          <img src={Scroll} alt="!!" className='home_scroll_img'/>
          <span className="home_scroll-name">Scroll Down</span>
          <i className="uil uil-arrow-down home_scroll-arrow"></i>
        </a>
    </div>
  )
}

export default ScrollDown