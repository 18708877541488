import React, { useState } from 'react'
import './projects.css'

const Projects = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index)
    }
    return (
        <section className="projects section" id="projects">
            <h2 className="section_title">Projects</h2>
            <span className="section_subtitle">The best projects I have done or am currently working on</span>
            <div className="projects_container container grid">
                <div className="projects_content">
                    <div>
                        <i className="uil uil-fire projects_icon"></i>
                        <h3 className="projects_title">Wildfire <br /> anticipation</h3>
                    </div>
                    <span className="projects_button" onClick={()=>toggleTab(1)}>More information
                        <i className="uil uil-arrow-right projects_button-icon"></i>
                    </span>



                    <div className={toggleState === 1 ? "projects_modal active-modal":"projects_modal"}>
                        <div className="projects_modal-content">
                            <i onClick={()=>toggleTab(0)} className="ui uil-times projects_modal-close"></i>
                            <h3 className="projects_modal-title">SDIS 83</h3>
                            <p className="projects_modal-description">Implementation of a new wildfire anticipation algorithm for firefighters' use</p>
                            <ul className="projects_modal-project grid">
                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Design and development of the algorithm</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Design of a new architecture</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Gradual migration to the new structure</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Development of a comprehensive test plan</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="projects_content">
                    <div>
                        <i className="uil uil-reddit-alien-alt projects_icon"></i>
                        <h3 className="projects_title">Web scraping &<br /> Data analysis </h3>
                    </div>
                    <span  onClick={()=>toggleTab(2)} className="projects_button">More information
                        <i className="uil uil-arrow-right projects_button-icon"></i>
                    </span>



                    <div className={toggleState === 2 ? "projects_modal active-modal":"projects_modal"}>
                        <div className="projects_modal-content">
                            <i onClick={()=>toggleTab(0)} className="ui uil-times projects_modal-close"></i>
                            <h3 className="projects_modal-title">Social Network Data</h3>
                            <p className="projects_modal-description">This project, developed for biology researchers, collects biodiversity data from various social networks. The data is then analyzed using neural networks.</p>
                            <ul className="projects_modal-project grid">
                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Collect as much data as possible from YouTube, Instagram, Reddit, Flickr</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Format the collected data into CSV files</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Analyze the retrieved data based on environment type and sentence polarity</p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>


                <div className="projects_content">
                    <div>
                        <i className="uil uil-pizza-slice projects_icon"></i>
                        <h3 className="projects_title">Pizzeria<br />Schedule</h3>
                    </div>
                    <span  onClick={()=>toggleTab(3)} className="projects_button">More information
                        <i className="uil uil-arrow-right projects_button-icon"></i>
                    </span>


                    <div className={toggleState === 3 ? "projects_modal active-modal":"projects_modal"}>
                        <div className="projects_modal-content">
                            <i onClick={()=>toggleTab(0)} className="ui uil-times projects_modal-close"></i>
                            <h3 className="projects_modal-title">Atavola</h3>
                            <p className="projects_modal-description">A Java-based application designed to manage staff, schedules, and meetings for a pizzeria, tailored to meet specific management needs</p>
                            <ul className="projects_modal-project grid">
                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Employee management, meetings, and shifts</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Design of a relational database for persistence</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Implementation of a heavy client in Swing and a backend using JDBC</p>
                                </li>

                                <li className="projects_modal-projects">
                                    <i className="uil uil-check-circle projects_modal-icon"></i>
                                    <p className="projects_modal-info">Development of a real-time web application connected to the main application</p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Projects