import React, { useEffect, useState } from 'react'
import "./header.css"

const Header = () => {
    const [showScroll, setShowScroll] = useState(false);
    const [toggle, setToggle] = useState(false)
    const [activeNav, setActiveNav] = useState("#home")

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 80) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const updateURL = (sectionId) => {
            if (sectionId !== '#home') {
                window.history.pushState({}, '', sectionId);
            } else {
                window.history.pushState({}, '', '/');
            }
        };

        const sections = document.querySelectorAll('section[id]');
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveNav(`#${entry.target.id}`);
                    updateURL(`#${entry.target.id}`);
                }
            });
        }, { threshold: 0.5 });

        sections.forEach((section) => {
            observer.observe(section);
        });

        return () => {
            sections.forEach((section) => {
                observer.unobserve(section);
            });
        };
    }, []);

    const handleToggle = () => {
        setToggle(!toggle);
    }

    const handleNavClick = (e, sectionId) => {
        e.preventDefault();
        setActiveNav(sectionId);
        const element = document.querySelector(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        if (sectionId === '#home') {
            window.history.pushState({}, '', '/');
        } else {
            window.history.pushState({}, '', sectionId);
        }
    };

    return (
        <header className={showScroll ? "header scroll-header" : "header"} id="header">
            <nav className="nav container">
                <a href="#home" onClick={(e) => handleNavClick(e, "#home")} className="nav_logo">Hery</a>
                <div className={toggle ? "nav_menu show-menu" : "nav_menu"}>
                    <ul className="nav_list">
                        <li className="nav_item">
                            <a href="#home" onClick={(e) => handleNavClick(e, "#home")} className={activeNav === "#home" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-estate nav_icon active_link"></i>Home
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#about" onClick={(e) => handleNavClick(e, "#about")} className={activeNav === "#about" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-user nav_icon"></i>About
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#skills" onClick={(e) => handleNavClick(e, "#skills")} className={activeNav === "#skills" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-star nav_icon"></i>Skills
                            </a>
                        </li>
                
                        <li className="nav_item">
                            <a href="#projects" onClick={(e) => handleNavClick(e, "#projects")} className={activeNav === "#projects" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-rocket nav_icon"></i>Projects
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#qualification" onClick={(e) => handleNavClick(e, "#qualification")} className={activeNav === "#qualification" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-graduation-cap nav_icon"></i>Qualification
                            </a>
                        </li>
                        <li className="nav_item">
                            <a href="#contact" onClick={(e) => handleNavClick(e, "#contact")} className={activeNav === "#contact" ? "nav_link active-link" : "nav_link"}>
                                <i className="uil uil-message nav_icon"></i>Contact
                            </a>
                        </li>
                    </ul>

                    <i className="uil uil-times nav_close" onClick={handleToggle}></i>
                </div>

                <div className="nav_toggle" onClick={handleToggle}>
                    <i className="uil uil-apps"></i>
                </div>
            </nav>
        </header>
    )
}

export default Header
