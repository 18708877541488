import React from 'react'
import "./about.css"
import AboutImg from "../../assets/profile.jpg"
import Info from "./Info"
import CV from "../../assets/resume.pdf"
import File from "../../assets/files.svg"

const About = () => {
  return (
   <section className="about section" id='about'>
    <h2 className="section_title">About Me</h2>
    <span className="section_subtitle">Let me introduce myself</span>
    <div className="about_container container grid">
        <img src={AboutImg} alt="me" className="about_img" />

        <div className="about_data">
            <Info/>

            <p className="about_description">
            Full Stack Developer, I create applications from scratch. 
            I'm also a Computer Engineering student, and I've been learning a lot 
            about the tech world
            </p>

            <a download="" href={CV} className="button button-flex">
                <p className='text-with-margin'>Resume</p>
                <img src={File} alt="file" className="file" />
            </a>
        </div>
    </div>
   </section>
  )
}

export default About