import React from 'react'

const Social = () => {
  return (
    <div className="home_social">
        <a href="https://github.com/Hery-R" className="home_social-icon" target='blank'>
            <i className="uil uil-github"></i>
        </a>

        <a href="https://www.linkedin.com/in/hery-rasoamia-382211326" className="home_social-icon" target='blank'>
            <i className="uil uil-linkedin"></i>            
        </a>
    </div>
  )
}

export default Social