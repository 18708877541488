import React from 'react'

const More = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">More skills</h3>
            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">System architecture</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Object-oriented</h4>
                        </div>
                    </div>


                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">API-building</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Algorithms</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Unit-testing</h4>
                        </div>
                    </div>

                </div>

                <div className="skills_group">
                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Software architecture</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Integration-testing</h4>
                        </div>
                    </div>


                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Requirements-analysis</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Agile</h4>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className="bx bx-badge-check"></i>
                        <div>
                            <h4 className="skills_name">Deployement</h4>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default More