import React from 'react'
import "./skills.css"
import Software from './Software'
import More from './More'


const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section_title">Skills</h2>
        <span className="section_subtitle">Concepts I have learned and can apply</span>
        <div className="skills_container container grid">
            <Software/>
            <More/>
        </div>
    </section>
  )
}

export default Skills