import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import Send from"../../assets/send.svg"
import "./contact.css"

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_h9gehov', 'template_ryln6ye', form.current, {
          publicKey: 'kSPMS4kDF8sj_lm__',
        })
       e.target.reset()
    };

    return (
        <section className="contact section" id="contact">
            <h2 className="section_title">Get in touch</h2>
            <span className="section_subtitle">Contact Me</span>

            <div className="contact_container container grid">
                <div className="contact_content">
                    <h3 className="contact_title">Talk to me</h3>

                    <div className="contact_info">
                        <div className="contact_card">
                            <i className="bx bx-mail contact_card-icon"></i>
                            <h3 className="contact_card-title">Email</h3>
                            <span className="contact_card-data">hery@rasoam.com</span>
                            <a href="mailto:hery@rasoam.com" className="contact_button">Write me
                                <i className="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>

                        <div className="contact_card">
                            <i className="bx bx-linkedin contact_card-icon"></i>
                            <h3 className="contact_card-title">LinkedIn</h3>
                            <span className="contact_card-data">Hery Rasoamia</span>
                            <a href="https://www.linkedin.com/in/hery-rasoamia-382211326" className="contact_button">Write me
                                <i className="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contact_content">
                    <h3 className="contact_title">Send me a message</h3>

                    <form  ref={form} onSubmit={sendEmail} className="contact_form">
                        <div className="contact_form-div">
                            <label className="contact_form-tag">Name</label>
                            <input type="text" name="name" className='contact_form-input' placeholder='Insert your name' />
                        </div>
                    

                    <div className="contact_form-div">
                        <label className="contact_form-tag">Mail</label>
                        <input type="email" name="email" className='contact_form-input' placeholder='Insert your email' />
                    </div>

                    <div className="contact_form-div contact_form-area">
                        <label className="contact_form-tag">Message</label>
                        <textarea name="message" cols="30" row="30" className='contact_form-input' placeholder='Write your message'></textarea>
                    </div>

                    <button className="button button--flex">
                        Send Message
                        <img src={Send} alt="!!" />
                    </button>
                    </form>
                         
                </div>
            </div>

        </section>
    )
}

export default Contact