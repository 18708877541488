import React from 'react'
import "./qualification.css"

const Qualification = () => {
    return (
        <section className="qualification section" id="qualification">
            <h2 className="section_title">Qualification</h2>
            <span className="section_subtitle">Let me share my personal journey with you</span>

            <div className="qualification_container container">
                <div className="qualification_sections">
                    <div className="qualification_content">
                        <div className="qualification_data">
                            <div>
                                <h3 className="qualification_title">Master's in Software Engineering</h3>
                                <span className="qualification_subtitle">Savoie Mont Blanc University</span>
                                <div className="qualification_calendar">
                                    <i className="uil uil-calendar-alt"></i>2024-Present
                                </div>
                            </div>
                            <div>
                                <span className="qualification_rounder"></span>
                                <span className="qualification_line"></span>
                            </div>
                        </div>

                        <div className="qualification_data">
                            <div></div>
                            <div>
                                <span className="qualification_rounder"></span>
                                <span className="qualification_line"></span>
                            </div>
                            <div>
                                <h3 className="qualification_title">Bachelor's in Software Engineering</h3>
                                <span className="qualification_subtitle">Savoie Mont Blanc University</span>
                                <div className="qualification_calendar">
                                    <i className="uil uil-calendar-alt"></i>2021-2024
                                </div>
                            </div>

                        </div>

                        <div className="qualification_data">
                            <div>
                                <h3 className="qualification_title">Introduction to the digital world</h3>
                                <span className="qualification_subtitle">Simplon Chambéry</span>
                                <div className="qualification_calendar">
                                    <i className="uil uil-calendar-alt"></i>March-June 2021
                                </div>
                            </div>
                            <div>
                                <span className="qualification_rounder"></span>
                                <span className="qualification_line"></span>
                            </div>
                        </div>

                        <div className="qualification_data">
                            <div></div>
                            <div>
                                <span className="qualification_rounder"></span>
                                <span className="qualification_line"></span>
                            </div>
                            <div>
                                <h3 className="qualification_title">Law studies</h3>
                                <span className="qualification_subtitle">Antananarivo University</span>
                                <div className="qualification_calendar">
                                    <i className="uil uil-calendar-alt"></i>2018-2020
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Qualification