import React from 'react'
import Hand from"../../assets/hand.svg"
import Send from"../../assets/send.svg"

const Data = () => {
  return (
    <div className="home_data">
        <h1 className="home_title">Hery Rasoa
        <img src={Hand} alt="!!" />
        </h1>

        <h3 className="home_subtitle">Computer Engineer</h3>
        <p className="home_description">Bienvenue! As a full-stack developer based in France, I invite you to experience my world of tech magic.</p>
        <a href="#contact" className="button button-flex">
        <p>Contact me</p>
        <img src={Send} alt="!!" />
        </a>



    
    </div>
  )
}

export default Data