import React from 'react'

const Info = () => {
  return (
    <div className="about_info grid">
        <div className="about_box">
            <i className="bx bx-award"></i>
            <h3 className="about_title">Knowledge</h3>
            <span className="about_subtitle">5 years of learning</span>
        </div>

        <div className="about_box">
             <i class="bx bx-brush-alt"></i>
            <h3 className="about_title">Versatile</h3>
            <span className="about_subtitle">Multi-talented</span>
        </div>

        <div className="about_box">
            <i class="bx bx-search-alt-2"></i>
            <h3 className="about_title">Looking for</h3>
            <span className="about_subtitle">New opportunities</span>
        </div>
    </div>
  )
}

export default Info